<div class="form-header no-border no-padding-bottom bottom20">
  <div class="flex-space-between">
    <div class="form-title f16 medium">Create Quickbooks Bill</div>
    <div *ngIf="!onProgress">
      <i (click)="onBtnClose()" class="ic-close clickable" nz-icon nzTheme="outline" nzType="close"></i>
    </div>
  </div>
</div>
<div class="bottom20" style="min-height: 200px;">
  <div *ngIf="isLoadingService" style="padding-top: 20px;">
    <nz-spin nzTip="Loading..."></nz-spin> 
  </div>
  <div *ngIf="!isLoadingService">
    <div *ngIf="errorAuthMsg">
      <div class="error bottom10"> {{ errorAuthMsg }}</div>
      <button nz-button class="btn-connect-to-quickbooks" (click)="onBtnConnectToQuickbooks()">Connect to Quickbooks</button>
    </div>
    <div *ngIf="!errorAuthMsg">
      <!-- begin view main-->
      <ng-container *ngIf="isLoadingFinAccount || !finAccountData">
        <nz-spin nzTip="Loading data..."></nz-spin> 
      </ng-container>
      <ng-container *ngIf="!isLoadingFinAccount && finAccountData">
        <div *ngIf="jobCode" class="flex bottom10">
          <div class="w170">Route Code</div>
          <div class="flex1">
            {{ jobCode }}
            <i nz-icon nzType="copy" nzTheme="outline" class="copy clickable" style="padding-left: 8px;" (click)="copyJobCode()"></i>
          </div>
          <div *ngIf="shouldShowFormCreateBill">
            <div input-search-qb-bill></div>
          </div>
        </div>
        <div class="flex bottom10">
          <div class="w170">Fin Job Amount</div>
          <div class="medium">
            {{ carrierCost }}
          </div>
        </div>
        <div class="flex bottom10">
          <div class="w170">WARP Carrier</div>
          <div>
            <a target="_blank" class="link" [routerLink]="[routeAdminCarrierList, finAccountData.account?.id]">{{ finAccountData.account?.name }}</a>
          </div>
        </div>
        <div class="flex bottom10">
          <div class="w170">Quickbooks Vendor</div>
          <div class="flex1">
            <div *ngIf="qbVendorInfo?.Id && qbVendorInfo?.CompanyName">
              <b><a [href]="qbVendorDetailLink" target="_blank" nz-tooltip="Go to Quickbooks">
                {{ qbVendorInfo.CompanyName }}
              </a></b>
            </div>
            <div *ngIf="!qbVendorInfo?.Id || !qbVendorInfo?.CompanyName" style="color: red;">
              Not found
            </div>
          </div>
          <div>
            <button nz-button nzSize="small" (click)="onBtnSearchQBVendor()">
              {{ labelSearchVendor }}
            </button>
          </div>
        </div>
        <div *ngIf="shouldShowFormCreateBill && formInput">
          <form nz-form [formGroup]="formInput" class="form-detail" [formGroup]="formInput" nzLayout="vertical">
            <div nz-row [nzGutter]="16">
              <div nz-col nzMd="6">
                <div *ngIf="'paymentTerms' as key">
                  <nz-form-item>
                    <nz-form-label>{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></nz-form-label>
                    <nz-form-control>
                      <nz-select nzBackdrop="true" [formControlName]="key" [nzLoading]="isFetchingTerms"
                        (ngModelChange)="onPaymentTermChange($event)"
                        nzPlaceHolder="Please select term" nzDropdownClassName="choose-carrier-payment-term">
                        <nz-option nzCustomContent *ngFor="let option of qbPaymentTermOptions" [nzLabel]="option.name" [nzValue]="option.id">
                          {{ option.name }}
                        </nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
              <div nz-col nzMd="6">
                <div *ngIf="'billDate' as key">
                  <nz-form-item>
                    <nz-form-label>{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></nz-form-label>
                    <div class="flex">
                      <nz-form-control>
                        <nz-date-picker nz-input class="full-w"
                          [formControlName]="key"
                          (ngModelChange)="onBillDateChange($event)"
                          nzFormat="yyyy-MM-dd">
                        </nz-date-picker>
                      </nz-form-control>
                    </div>
                  </nz-form-item>
                </div>
              </div>
              <div nz-col nzMd="6">
                <div *ngIf="'dueDate' as key">
                  <nz-form-item>
                    <nz-form-label>{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></nz-form-label>
                    <div class="flex">
                      <nz-form-control>
                        <nz-date-picker nz-input class="full-w"
                          [formControlName]="key"
                          nzFormat="yyyy-MM-dd">
                        </nz-date-picker>
                      </nz-form-control>
                    </div>
                  </nz-form-item>
                </div>
              </div>
              <div nz-col nzMd="6">
                <div *ngIf="'billNo' as key">
                  <nz-form-item>
                    <nz-form-label>{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></nz-form-label>
                    <div class="flex">
                      <nz-form-control>
                        <input nz-input [placeholder]="getPlaceHolder(key)" (input)="onBillNoChange($event)"
                          [formControlName]="key"/>
                      </nz-form-control>
                    </div>
                  </nz-form-item>
                </div>
              </div>
            </div>
            <div class="bottom10 flex">
              <div class="f15 medium">{{ getLabel('lines') }}</div>
              <button nz-button class="left15 f13" nzSize="small" (click)="onBtnAddCategoryLine()">Add lines</button>
            </div>
            <div nz-row [nzGutter]="16" class="bottom10">
              <div nz-col *ngFor="let col of row1.columns" [nzSpan]="col.span">
                <div [ngClass]="{'text-align-end': col.key === 'action'}">
                  {{getLabel(fullKey('lines', col.key))}}<span *ngIf="isRequired(fullKey('lines', col.key))" class="label-mark-required"></span>
                </div>
              </div>
            </div>
            <div *ngFor="let groupKey of ['lines']" [formArrayName]="groupKey">
              <form *ngFor="let item of getArrayControls(groupKey); let lineIndex = index" nz-form [formGroupName]="lineIndex">
                <div nz-row [nzGutter]="16" class="line-item">
                  <div nz-col *ngFor="let col of row1.columns" [nzSpan]="col.span">
                    <div *ngIf="col.key == 'id'">{{ lineIndex + 1 }}</div>
                    <div *ngIf="col.key == 'accountRefId'">
                      <nz-form-item>
                        <nz-form-control>
                          <nz-select nzBackdrop="true" [formControlName]="col.key" [nzLoading]="isFetchingCateroryAccount"
                            nzShowSearch nzPlaceHolder="Please select category" nzDropdownClassName="choose-qb-category-line"
                            (ngModelChange)="onChangeCategory(lineIndex)">
                            <nz-option 
                              nzCustomContent 
                              *ngFor="let option of qbCategoryAccountOptions" 
                              [nzValue]="option.id"
                              [nzLabel]="getCategoryLineLabel(option)" 
                            >
                              <div class="flex-space-between">
                                <div>
                                  <span *ngIf="option.accountNum" class="right10">{{ option.accountNum }}</span>
                                  <span>{{ option.name }}</span>
                                </div>
                                <div style="font-style: italic; color: #adadad">{{ option.accountType ?? ''}}</div>
                              </div>
                            </nz-option>
                          </nz-select>
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                    <div *ngIf="col.key == 'description'">
                      <textarea 
                        nz-input [formControlName]="col.key" 
                        [placeholder]="getPlaceHolder(fullKey(groupKey, col.key))" 
                        [nzAutosize]="{minRows: 1, maxRows: 6}">
                      </textarea>
                    </div>
                    <div *ngIf="col.key == 'amount'">
                      <nz-form-item>
                        <nz-form-control>
                          <nz-input-group [nzAddOnAfter]="getAddOnInputAmount(lineIndex)">
                            <input nz-input [formControlName]="col.key"
                            [type]="getInputType(fullKey(groupKey, col.key))"
                            [placeholder]="getPlaceHolder(fullKey(groupKey, col.key))"
                            (input)="onInputChanged($event, fullKey(groupKey, col.key), lineIndex)"
                            (keypress)="onInputKeyPress($event, fullKey(groupKey, col.key))"
                            (focusout)="onInputFocusOut($event, fullKey(groupKey, col.key))">
                          </nz-input-group>
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                    <div *ngIf="col.key == 'action'" style="display: flex; flex-direction: row-reverse; align-items: center; padding-top: 5px;">
                      <div class="pointer">
                        <span nz-icon nzType="delete" nzTheme="outline" class="danger" (click)="onBtnDeleteLine(lineIndex)" style="font-size: 18px;"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div nz-row [nzGutter]="16" class="bottom20">
              <div nz-col [nzSpan]="17" class="f15 medium">Payable Amount</div>
              <div nz-col [nzSpan]="7" class="f15 medium">{{ getBalanceDue }}</div>
            </div>
            <div nz-row [nzGutter]="16" class="top10">
              <div nz-col [nzMd]="12" *ngIf="'memo' as key">
                <div class="bottom5">
                  {{ getLabel(key) }}
                  <span *ngIf="isRequired(key)" class="label-mark-required"></span>
                  <i nz-icon nzType="copy" nzTheme="outline" class="copy clickable" style="padding: 8px;" (click)="copyMemoValue()"></i>
                </div>
                <textarea 
                  nz-input [formControlName]="key" 
                  [placeholder]="getPlaceHolder(key)" 
                  [nzAutosize]="{minRows: 3, maxRows: 6}">
                </textarea>
              </div>
              <div nz-col [nzMd]="12" *ngIf="'attachmentFiles' as key">
                <div class="bottom5">{{ getLabel(key) }}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
                <div>
                  <input #inputFile type="file" hidden accept=".png,.jpg,.jpeg,.pdf" (change)="onAttachmentFileSelected(key, inputFile.files)">
                  <div *ngIf="!getAttachedFileValue(key)?.length" class="flex">
                    <button nz-button nzType="default" (click)="inputFile.click()">
                      <i nz-icon nzType="upload" nzTheme="outline"></i>Upload
                    </button>
                  </div>
                  <ng-container *ngIf="getAttachedFileValue(key).length">
                    <div class="flex bottom5">
                      <button nz-button nzType="default" (click)="inputFile.click()">
                        <i nz-icon nzType="upload" nzTheme="outline"></i>Upload
                      </button>
                    </div>
                    <ng-container *ngFor="let valueKey of getAttachedFileValue(key)">
                      <div *ngIf="hasAttachedFile(valueKey)">
                        <div class="flex-space-between" style="align-items: center;">
                          <div class="file-selected">
                            <i nz-icon nzType="paper-clip" nzTheme="outline"></i>{{ labelSelectFile(valueKey) }}
                          </div>
                          <button nz-button nzType="text" (click)="delDocumentFile(key, valueKey, inputFile)">
                            <i nz-icon nzType="delete"></i>
                          </button>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="top20 note">
              <span class="bold">Note: </span>
              <span *ngIf="paymentTermVersionData?.isUseNewConfig">
                Using new percent (%) charge load (A minimum charge applies depending on the service period: <span class="bold">$12 for next-day service, $10 for 7-days service, and $8 for 15-days service</span>. 
                If the calculated fee is less than $12; otherwise, the standard percentage-based fee will be charged)
              </span>
              <span *ngIf="!paymentTermVersionData?.isUseNewConfig">Using old percent (%) charge load</span>
              <div *ngIf="paymentTermVersionData.insertWhen">
                Due to job created at <span class="bold">{{ formatTimestampToDisplay(paymentTermVersionData.insertWhen) }} PST</span>
                {{ paymentTermVersionData?.isUseNewConfig ? 'after' : ' before' }} Oct 01, 2024 12:00 am PST.
              </div>
            </div>
          </form>
          <div class="top20 bottom20 flex" style="flex-direction: row-reverse;" >
            <button 
              nz-button 
              nzType="primary" 
              [nzLoading]="onProgress"
              [disabled]="!needUpdate || onProgress"
              (click)="onBtnCreateBill()"
            >Create Bill</button>
          </div>
        </div>
      </ng-container>
      
      <!-- end view main-->
    </div>
  </div>
</div>





