<div style="margin: 0px; padding: 0px;">
  <div style="width: 100%">
    <div style="display: flex; justify-content: space-between;align-items: center; width: 100%; padding-left: 4px">
      <nz-tabset [(nzSelectedIndex)]="selectedTabIndex" [nzSize]="'small'" nzAnimated="false" style="width: 100%" type="card">
        <ng-container *ngFor="let tab of filterTabs">
          <nz-tab [nzTitle]="titleTemplate" style="font-weight: 600"></nz-tab>
          <ng-template #titleTemplate>
            <nz-badge [nzCount]="tab?.total" [nzOverflowCount]="99999" nzShowZero [nzStyle]="{ backgroundColor: '#1b1ac4'}">
              <span>{{tab.title}}</span>
            </nz-badge>
          </ng-template>
        </ng-container>
      </nz-tabset>
      <div style="display: flex;align-items: center;">
      </div>
    </div>
    <div style="display: flex; justify-content: space-between;padding: 20px;background-color: white;margin-top: 20px">
      <form [formGroup]="formInput" nz-form>
        <div style="display: flex; flex-wrap: wrap;">
          <div class="form-item">
            <div class="bottom20 flex">
              <search-box #searchBox (doSearch)="doSearch($event)"
                [placeHolder]="'Search by Financial Job or Route'" [searchKeyword]="searchKeyword"
                style="width: 300px;"></search-box>
            </div>
          </div>
          <div class="form-item left10">
            <div *ngIf="'accountIds' as key">
              <nz-select nzBackdrop="true" nzAllowClear nzShowSearch nzMode="multiple"
                [formControlName]="key" [nzLoading]="isFetchingFinAccounts"
                [nzPlaceHolder]="getPlaceHolder(key)"
                nzDropdownClassName="ap-list-filter-{{key}}"
                (ngModelChange)="onChangeAccountId($event)" style="width: 100%;">
                <nz-option *ngFor="let item of listFinAccounts" 
                  [nzValue]="item.id" [nzLabel]="item.name">
                </nz-option>
              </nz-select>
            </div>
          </div>
          <div class="form-item left10">
            <div *ngIf="'hasStatement' as key">
              <div nz-checkbox [formControlName]="key" (ngModelChange)="onCheckboxHasStatement($event)">{{ getLabel(key) }}</div>
            </div>
          </div>
          <!-- <div *ngIf="isSelectCompletedTab()" class="form-item left10">
            <div class="date-picker-row bottom20 flex">
              <nz-date-picker (ngModelChange)="onChangeFilterDate($event, key)"
                  *ngFor="let key of ['fromDate', 'toDate']; let i = index"
                  [formControlName]="key"
                  [ngClass]="{'first':i==0, 'last':i==1}"
                  [nzPlaceHolder]="getPlaceHolder(key)" class="flex1"
                  nzFormat="yyyy-MM-dd">
              </nz-date-picker>
            </div>
          </div> -->
        </div>
      </form>
      <div>
        <button nz-button nzType="default" (click)="onBtnCreateStatement()">
          <i nz-icon nzType="plus"></i>Create Statement
        </button>
      </div>
    </div>
  </div>
</div>
  